'use client';

import useStore from '@/stores/useStore';
import cx from 'classnames';
import { FC } from 'react';
import Link from './Link';

export interface ProductFeaturedPlanProps {
  title: string;
  image: React.ReactNode;

  planTitle: string;
  planDescription: string;
  planLinks: {
    type: 'primary' | 'secondary';
    title: string;
    href: string;
    action: 'trial' | 'demo';
  }[];

  planSmallLink: {
    title: string;
    href: string;
  };
}

const ProductFeaturedPlan: FC<ProductFeaturedPlanProps> = ({ title, image, planTitle, planDescription, planLinks = [], planSmallLink }) => {
  const {
    setDemoModalOpen,
    setTrialModalOpen,
  } = useStore();

  const onClickMenuButton = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;

      default:
        break;
    }
  };

  const header = (
    <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop md:text-center">
      <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4 flex flex-col gap-3">
        <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">{title}</h2>
      </div>
    </div>
  );

  const plan = (
    <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
      <div className="lg:col-span-10 lg:col-start-2">
        <div className='bg-blue-200 rounded-10 overflow-hidden'>
          <div className='grid grid-cols-10 gap-x-gutter-mobile md:gap-x-gutter-desktop'>
            <div className='col-span-10 lg:col-span-7 p-3 md:py-8 md:px-6'>
              <div className='flex flex-col gap-2 md:gap-3'>
                <p className='text-25/30 md:text-43/52 font-500 tracking-tight text-blue-900'>{planTitle}</p>
                <p className='text-17/24 md:text-20/28 text-blue-900'>{planDescription}</p>
                <ul className="flex flex-col gap-2 md:flex-row items-start md:items-center">
                  {planLinks.map((link, index) => (
                    <li key={`plan-link-${link.title?.replace(/\s+/g, '-') ?? index}`}>
                      {link.href.length ? (
                        <Link
                          className={cx('block', {
                            'button button-size-medium button-primary-violet': link.type === 'primary',
                            'button button-size-medium button-secondary-violet': link.type === 'secondary',
                          })}
                          href={link.href}
                        >
                          {link.title}
                        </Link>
                      ) : (
                        <button
                          className={cx('block', {
                            'button button-size-medium button-primary-violet': link.type === 'primary',
                            'button button-size-medium button-secondary-violet': link.type === 'secondary',
                          })}
                          onClick={() => onClickMenuButton(link.action)}
                        >
                          {link.title}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
                <div className='flex'>
                  <Link href={planSmallLink.href} className='text-16/22 font-500'>{planSmallLink.title} <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline"><path d="M3.75 9H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 3.75L14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg></Link>
                </div>
              </div>
            </div>
            <div className='col-span-10 lg:col-span-3 relative'>{image}</div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="my-8 md:my-22 px-container-mobile md:px-container-desktop">
      <div className="max-w-grid mx-auto flex flex-col gap-y-6 md:gap-y-8">
        {header}
        {plan}
      </div>
    </div>
  );
};

export default ProductFeaturedPlan;
