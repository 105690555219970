'use client';

import ease from '@/constants/ease';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';

const DESKTOP_BREAKPOINT = 800;
const AUTOPLAY_INTERVAL = 12000;

export interface ProductFeatureCarouselProps {
  title: string;
  description: string;

  features: {
    title: string;
    description: string;
    image: React.ReactNode;
    icon: React.ReactNode;
  }[];
}

const ProductFeatureCarousel: FC<ProductFeatureCarouselProps> = ({ title, description, features = [] }) => {
  const currentSlideTimeout = useRef<any>(null);
  const [activeFeatureIndex, setFeatureIndex] = useState(0);
  const activeFeature = features[activeFeatureIndex];

  // Autoplay next slide when the slide changes
  useEffect(() => {
    clearTimeout(currentSlideTimeout.current);

    const autoplay = () => {
      if (window.innerWidth < DESKTOP_BREAKPOINT) return;

      if (activeFeatureIndex >= features.length - 1) {
        setFeatureIndex(0);
      } else {
        setFeatureIndex(activeFeatureIndex + 1);
      }
    };

    currentSlideTimeout.current = setTimeout(autoplay, AUTOPLAY_INTERVAL);
  }, [activeFeatureIndex]);

  const header = (
    <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop md:text-center">
      <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4 flex flex-col gap-3">
        <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">{title}</h2>
        <p className="text-17/24 text-gray-700">{description}</p>
      </div>
    </div>
  );

  const mobileGrid = (
    <div className="flex flex-col gap-y-6 md:hidden">
      {features.map((feature, index) => (
        <div key={`feature-${feature.title?.replace(/\s+/g, '-') ?? index}`} className="flex flex-col gap-y-2">
          <div className="relative aspect-[1222/688] rounded-10 overflow-hidden">{feature.image}</div>

          <div className="flex gap-1 items-center">
            {feature.icon}
            <p className="text-14/20 font-500">{feature.title}</p>
          </div>

          <p className="text-14/20 text-gray-700">{feature.description}</p>
        </div>
      ))}
    </div>
  );

  const desktopNav = (
    <div className="flex gap-1 items-center justify-center">
      {features.map((feature, index) => (
        <div key={`feature-nav-${feature.title?.replace(/\s+/g, '-') ?? index}`} className="w-25 relative flex flex-col gap-1 items-center">
          <button
            className={cx('block button button-size-medium button-drawer-violet', {
              active: index === activeFeatureIndex,
            })}
            onClick={() => setFeatureIndex(index)}
          >
            <div className="flex items-center gap-1">
              {feature.icon}
              <div>{feature.title}</div>
            </div>
          </button>

          <div className="h-[6px] w-full rounded-10 overflow-hidden bg-gray-100 relative">
            <AnimatePresence mode="wait">
              {index === activeFeatureIndex && (
                <motion.div
                  initial={{ width: 0 }}
                  animate={{
                    width: '100%',
                    transition: {
                      duration: AUTOPLAY_INTERVAL / 1000,
                      ease: 'linear',
                    },
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 400 / 1000,
                      ease: ease.appear,
                    },
                  }}
                  className="absolute inset-0 h-[6px] rounded-10 overflow-hidden bg-violet-800"
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      ))}
    </div>
  );

  const desktopActiveSlide = (
    <div className="hidden md:block w-full aspect-[1222/688] relative">
      <AnimatePresence mode="wait">
        {activeFeature && (
          <motion.div
            key={activeFeatureIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 400 / 1000,
              ease: ease.appear,
            }}
          >
            <div className="grid grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop gap-y-3">
              <div className="col-span-12 lg:col-span-10 lg:col-start-2">
                <div className="rounded-10 overflow-hidden">{activeFeature.image}</div>
              </div>
              <div className="col-span-10 col-start-2 lg:col-span-6 lg:col-start-4">
                <p className="text-14/20 text-gray-700 text-center">{activeFeature.description}</p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  return (
    <div className="px-container-mobile md:px-container-desktop">
      <div className="max-w-grid mx-auto flex flex-col gap-y-6 md:gap-y-8">
        {header}
        {mobileGrid}
        <div className="hidden md:flex flex-col gap-4">
          {desktopNav}
          <div className="relative">{desktopActiveSlide}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatureCarousel;
