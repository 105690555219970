'use client';

import ease from '@/constants/ease';
import useTouch from '@/hooks/useTouch';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { AnimationFeatureAccountManagementProps } from './AnimationFeatureAccountManagement';
import { AnimationFeatureHelpDeskProps } from './AnimationFeatureHelpDesk';
import { AnimationFeatureSharedInboxProps } from './AnimationFeatureSharedInbox';
import Link from './Link';

export interface HighlightedFeatureTile {
  backgroundColor: 'violet' | 'blue' | 'green';
  title: string;
  description: string;
  cta?: {
    title: string;
    href: string;
  };

  animationComponent:
  | FC<AnimationFeatureHelpDeskProps>
  | FC<AnimationFeatureSharedInboxProps>
  | FC<AnimationFeatureAccountManagementProps>;
}

export interface ProductHighlightedFeaturesProps {
  title: string;
  description?: string;
  features: HighlightedFeatureTile[];
}

const HighlightedFeatureTile: FC<HighlightedFeatureTile> = ({
  backgroundColor,
  title,
  description,
  cta,
  animationComponent,
}) => {
  const isTouch = useTouch();
  const [isHovering, setHovering] = useState(false);

  const Animation = animationComponent;

  const renderedAnimation = <Animation playing={isTouch || isHovering} />;

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={cx('rounded-10 flex flex-col justify-between gap-4 overflow-hidden', {
        'bg-violet-300 text-violet-900': backgroundColor === 'violet',
        'bg-blue-300 text-blue-900': backgroundColor === 'blue',
        'bg-green-300 text-green-900': backgroundColor === 'green',
      })}
    >
      <div className="pt-3 px-3 lg:px-4 lg:pt-5 flex flex-col gap-[12px]">
        <h3 className="font-500 tracking-tight text-25/30 lg:-mr-1">{title}</h3>
        <p className="text-17/24">{description}</p>
        {cta && (
          <div className="flex">
            <Link className="block button button-size-medium button-primary-violet" href={cta.href}>
              {cta.title} <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline"><path d="M3.75 9H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M9 3.75L14.25 9L9 14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
            </Link>
          </div>
        )}
      </div>

      <motion.div
        animate={{
          scale: isHovering ? 1.03 : 1,
        }}
        transition={{
          duration: 400 / 1000,
          ease: ease.appear,
        }}
      >
        {renderedAnimation}
      </motion.div>
    </div>
  );
};

const ProductHighlightedFeatures: FC<ProductHighlightedFeaturesProps> = ({ title, description, features }) => {
  const header = (
    <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop md:text-center">
      <div className="md:col-span-10 md:col-start-2 flex flex-col gap-3">
        <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">{title}</h2>
        {description && (
          <p className="text-17/24 text-gray-700">{description}</p>
        )}
      </div>
    </div>
  );

  const featuresGrid = (
    <div className="grid lg:grid-cols-3 gap-3 md:gap-gutter-desktop">
      {features.map((feature, index) => (
        <HighlightedFeatureTile key={`highlight-feature-tile-${feature.title?.replace(/\s+/g, '-') ?? index}`} {...feature} />
      ))}
    </div>
  );

  return (
    <div className="my-8 md:my-22 px-container-mobile md:px-container-desktop">
      <div className="max-w-grid mx-auto flex flex-col gap-y-6 md:gap-y-8">
        {header}
        {featuresGrid}
      </div>
    </div>
  );
};

export default ProductHighlightedFeatures;
