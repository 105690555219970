'use client';

import { FC } from 'react';
import IconArrowFooterLink from './IconArrowFooterLink';
import Link from './Link';

export interface ProductFeatureTilesProps {
  title: string;

  tiles: {
    icon: React.ReactNode;
    title: string;
    description: string;
    cta: {
      title: string;
      href: string;
    };
  }[];
}

const ProductFeatureTiles: FC<ProductFeatureTilesProps> = ({ title, tiles = [] }) => {
  const tileGrid = (
    <div className="grid gap-y-4 md:grid-cols-3 gap-x-gutter-mobile md:gap-x-gutter-desktop">
      {tiles.map((tile, index) => (
        <div
          key={`feature-tile-${tile.title?.replace(/\s+/g, '-') ?? index}`}
          className="relative bg-violet-100 rounded-10 transition-all duration-200 ease-in-out-cubic outline outline-0 hover:outline-8 outline-violet-300 px-3 py-4 lg:px-6"
        >
          <div className="flex flex-col gap-2 items-start">
            {tile.icon}

            <p className="text-25/30 font-500 text-violet-900">{tile.title}</p>

            <p className="text-violet-800 text-17/24">{tile.description}</p>

            <div className="flex gap-1 items-center text-violet-900">
              <Link href={tile.cta.href} className="text-16/22 font-500 link-cover">
                {tile.cta.title}
              </Link>

              <IconArrowFooterLink className="block" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="my-8 md:my-22 px-container-mobile md:px-container-desktop">
      <div className="max-w-grid mx-auto flex flex-col gap-y-4">
        <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
          <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
            <h2 className="font-500 tracking-tight text-30/36 md:text-52/62 md:text-center">{title}</h2>
          </div>
        </div>

        {tileGrid}
      </div>
    </div>
  );
};

export default ProductFeatureTiles;
