'use client';

import useStore from '@/stores/useStore';
import cx from 'classnames';
import { FC } from 'react';
import Link from './Link';

export interface ProductHeroProps {
  title: React.ReactNode;
  description: string;

  links: {
    type: 'primary' | 'secondary';
    title: string;
    href: string;
    action?: 'trial' | 'demo';
  }[];

  media: React.ReactNode;

  logosTitle: string;
  companyLogos: React.ReactNode[];
}

const ProductHero: FC<ProductHeroProps> = ({
  title,
  description,
  links = [],
  media,
  logosTitle,
  companyLogos = [],
}) => {
  const { setDemoModalOpen, setTrialModalOpen } = useStore();

  const onClickMenuButton = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;

      default:
        break;
    }
  };

  const header = (
    <div className="flex flex-col items-center gap-2 md:gap-3 text-center">
      <h1 className="font-500 tracking-tight text-36/42 md:text-54/54">{title}</h1>
      <p className="text-violet-200 text-17/24 max-w-80 text-pretty">{description}</p>
      <ul className="flex gap-1 md:gap-2">
        {links.map((link, index) => {
          return (
            <li key={`product-hero-link-${link.title?.replace(/\s+/g, '-') ?? index}`}>
              {link.href.length ? (
                <Link
                  className={cx('block button button-size-medium', {
                    'button-primary-white': link.type === 'primary',
                    'button-secondary-violet-300': link.type === 'secondary',
                  })}
                  href={link.href}
                >
                  {link.title}
                </Link>
              ) : (
                <button
                  className={cx('block button button-size-medium', {
                    'button-primary-white': link.type === 'primary',
                    'button-secondary-violet-300': link.type === 'secondary',
                  })}
                  onClick={() => {
                    onClickMenuButton(link.action);
                  }}
                >
                  {link.title}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );

  const logoFooter = (
    <div className="flex flex-col gap-6 md:gap-8">
      <h2 className="text-25/30 font-500 text-center">{logosTitle}</h2>
      <div className="grid grid-cols-2 gap-x-gutter-mobile gap-y-4 md:flex md:gap-10 md:flex-wrap md:items-center md:justify-center">
        {companyLogos}
      </div>
    </div>
  );

  return (
    <div className="bg-violet-900 text-white">
      <div className="-mt-header-height-mobile md:-mt-header-height-desktop pt-header-height-mobile md:pt-header-height-desktop">
        <div className="pt-11 md:pt-12 px-container-mobile md:px-container-desktop pb-10">
          <div className='max-w-grid mx-auto flex flex-col gap-y-3 md:gap-y-12'>
            <div className="grid grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
              <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">{header}</div>
            </div>

            <div className="grid grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop">
              <div className="col-span-12 md:col-span-10 md:col-start-2">{media}</div>
            </div>

            <div className="mx-auto max-w-grid">{logoFooter}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHero;
