'use client';

import { FC } from 'react';
import Link from './Link';

export interface ProductIntegrationGridProps {
  title: string;
  description: string;
  cta: {
    title: string;
    href: string;
  };

  integrations: {
    title: string;
    description: string;
    image: React.ReactNode;
  }[];
}

const ProductIntegrationGrid: FC<ProductIntegrationGridProps> = ({ title, description, cta, integrations = [] }) => {
  const header = (
    <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop md:text-center">
      <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4 flex flex-col gap-3">
        <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">{title}</h2>
        <p className="text-17/24 text-gray-700">{description}</p>
        <div className="flex justify-center items-center">
          <Link href={cta.href}
            className="block button button-size-medium button-secondary-gray">
            {cta.title}
          </Link>
        </div>
      </div>
    </div>
  );

  const integrationGrid = (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-4 gap-x-gutter-mobile md:gap-gutter-desktop">
      {integrations.map((integration, index) => (
        <div key={`integration-grid-item-${integration.title?.replace(/\s+/g, '-') ?? index}`} className="flex flex-col gap-1 bg-green-200 overflow-hidden rounded-10 pt-4">
          <p className="px-4 font-500 text-25/30 tracking-tight text-green-900">{integration.title}</p>
          <p className="px-4 text-17/24 text-green-800">{integration.description}</p>
          <div className='mt-2' style={{ marginLeft: '20px', marginRight: '20px' }}>
            {integration.image}
          </div>
        </div>
      ))}
    </div>
  )


  return (
    <div className="my-8 md:my-22 px-container-mobile md:px-container-desktop">
      <div className="max-w-grid mx-auto flex flex-col gap-y-6 md:gap-y-8">
        {header}
        {integrationGrid}
      </div>
    </div>
  );
};

export default ProductIntegrationGrid;
