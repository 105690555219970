'use client';

import { FC, SVGProps } from 'react';

const IconFeatureEscalations: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M17 18L12 13L7 18" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 11L12 6L7 11" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconFeatureEscalations;
