'use client';

import cx from 'classnames';
import Image from 'next/image';
import { FC } from 'react';
import IconGridBreakup from './IconGridBreakup';
import Link from './Link';

export interface ProductFeatureGridProps {
  title: string;
  description?: string;

  features: {
    icon: React.ReactNode;
    cta: {
      title: string;
      href: string;
    };
    description: string;
  }[];

  featureHighlights: {
    title: string;
    subtitle: string;
    description: string;
    image: React.ReactNode;
    features: string[];
  }[];
}

const ProductFeatureGrid: FC<ProductFeatureGridProps> = ({ title, description, features = [], featureHighlights = [], }) => {
  const header = (
    <div className="grid md:grid-cols-12 gap-x-gutter-mobile md:gap-x-gutter-desktop md:text-center">
      <div className="md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 flex flex-col gap-3">
        <h2 className="font-500 tracking-tight text-30/36 md:text-52/62">{title}</h2>
        {description && <p className="text-17/24 text-gray-700">{description}</p>}
      </div>
    </div>
  );

  const featuresGrid = (
    <div className="grid xl:grid-cols-12 gap-x-gutter-desktop">
      <div className="xl:col-span-10 xl:col-start-2 flex flex-col gap-3">
        <div className="grid gap-y-6 md:gap-y-4 grid-cols-2 md:grid-cols-4 gap-x-gutter-mobile md:gap-x-gutter-desktop text-gray-900 lg:px-2">
          {features.map((feature, index) => (
            <div
              key={`feature-grid-item-${feature.cta.title?.replace(/\s+/g, '-') ?? index}`}
              className="relative p-[12px] duration-200 ease-in-out-quad hover:bg-gray-200 hover:bg-opacity-50 rounded-10"
            >
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2 md:gap-1 md:flex-row md:items-center">
                  <div className='mix-blend-multiply'>
                    {feature.icon}
                  </div>
                  <p className="font-500 tracking-tight text-20/24">
                    <Link href={feature.cta.href} className="link-cover">
                      {feature.cta.title}
                    </Link>
                  </p>
                </div>
                <p className="text-17/24 text-gray-700">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const featureHighlightsGrid = (
    <div className="py-8 md:py-25 flex flex-col gap-y-10 md:gap-y-15">
      {featureHighlights.map((highlight, index) => (
        <div key={`feature-highlight-${highlight.title?.replace(/\s+/g, '-') ?? index}`} className="grid grid-cols-12 gap-gutter-mobile md:gap-x-gutter-desktop gap-y-6 items-center">
          <div className={cx('col-span-12 md:col-span-6 lg:col-span-5 md:row-start-1 lg:row-start-1', {
            'md:col-start-1 lg:col-start-1': index % 2 === 1,
            'md:col-start-7 lg:col-start-8': index % 2 === 0,
          })}>
            <div className="flex flex-col gap-3">
              <h3 className="text-14/20 uppercase tracking-wide text-gray-700">{highlight.subtitle}</h3>
              <p className="text-30/36 md:text-36/42 font-500 tracking-tight">{highlight.title}</p>
              <p className="text-20/26 text-gray-700">{highlight.description}</p>

              <ul className='flex flex-col gap-2 md:gap-3'>
                {highlight.features.map((feature, index) => (
                  <li key={`feature-highlight-feature-${feature?.replace(/\s+/g, '-') ?? index}`} className='text-17/20 text-gray-800 flex gap-1 items-center'>
                    <Image
                      src="https://front.com/webassets/rebrand/icon-product-feature-check.svg"
                      alt="Check"
                      width={24}
                      height={24}
                      className='block'
                    />
                    <p>{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className={cx("col-span-12 md:col-span-6 md:row-start-1 lg:row-start-1", {
            'md:col-start-7 lg:col-start-7': index % 2 === 1,
            'md:col-start-1 lg:col-start-1': index % 2 === 0,
          })}>
            {highlight.image}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="h-30 md:h-[21vw] relative w-full overflow-hidden">
        <IconGridBreakup className="absolute left-[-100vw] right-[-100vw] bottom-[-1px] w-[300vw] md:left-0 md:right-0 md:w-full h-auto text-gray-100" />
      </div>
      <div className="xl:pt-4 px-container-mobile md:px-container-desktop bg-gray-100">
        <div className='max-w-grid mx-auto'>
          <div className="flex flex-col gap-y-6 md:gap-y-8">
            {header}
            {featuresGrid}
          </div>
          {featureHighlightsGrid}
        </div>
      </div>
    </div>
  );
};

export default ProductFeatureGrid;
