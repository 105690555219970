import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/AnimationFeatureAccountManagement.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/AnimationFeatureHelpDesk.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/AnimationFeatureSharedInbox.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/IconFeatureEscalations.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/IconFeatureHandoffs.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/IconFeatureInsights.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/IconFeatureWorkflows.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/PageBumper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductAwards.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductFeatureCarousel.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductFeaturedPlan.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductFeatureGrid.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductFeatureTiles.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductHero.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductHighlightedFeatures.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductIntegrationGrid.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductOverviewEscalations.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductOverviewHandoffs.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductOverviewInsights.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/frontweb/frontweb/src/components/_rebrand/ProductOverviewWorkflows.tsx")